import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import * as dat from 'dat.gui';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
const hdrTextureURL = new URL('../assets/img/MR_INT-006_LoftIndustrialWindow_Griffintown.hdr', import.meta.url);

const motorUrl = new  URL('../assets/drone.glb', import.meta.url);

const renderer = new THREE.WebGLRenderer({antialias: true});
renderer.shadowMap.enabled = true;

renderer.setSize(window.innerWidth, window.innerHeight);

document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);

const orbit = new OrbitControls(camera, renderer.domElement);

const axesHelper = new THREE.AxesHelper(5);
//scene.add(axesHelper);

const mouse = new THREE.Vector2();
const intersectionPoint = new THREE.Vector3();
const planeNormal = new THREE.Vector3();
const planee = new THREE.Plane();
const raycaster = new THREE.Raycaster();

window.addEventListener('mousemove',function(e){
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
    planeNormal.copy(camera.position).normalize();
    planee.setFromNormalAndCoplanarPoint(planeNormal,scene.position);
    raycaster.setFromCamera(mouse,camera);
    raycaster.ray.intersectPlane(planee, intersectionPoint);
});


window.addEventListener('click',function(e){
    const sphereGeo = new THREE.SphereGeometry(0.5,200,200);
    const sphereMat = new THREE.MeshStandardMaterial({
        color: 0XFFEA00,
        metalness: 0,
        roughness: 0
    });
    const sphereMesh = new THREE.Mesh(sphereGeo, sphereMat);
    //scene.add(sphereMesh);
    sphereMesh.position.copy(intersectionPoint);

});






camera.position.set(-40,60,100);
orbit.update();

renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1.2;

const boxGeometry = new THREE.BoxGeometry();
const boxMaterial = new THREE.MeshStandardMaterial({color: 0x00FF00});
const box = new THREE.Mesh(boxGeometry,boxMaterial);

//scene.add(box);

const planeGeometry = new THREE.PlaneGeometry(5000,5000);
const planeMaterial = new THREE.MeshStandardMaterial({color: 0xFFFFFFFF});
const plane = new THREE.Mesh(planeGeometry,planeMaterial);
//scene.add(plane);
plane.rotation.x = -0.5 * Math.PI;
plane.receiveShadow = true;
const gridHelper = new THREE.GridHelper(30);
//scene.add(gridHelper);

const sphereGeometry = new THREE.SphereGeometry(3,50,50);
const sphereMaterial = new THREE.MeshStandardMaterial({
    color: 0x0000FF,
    wireframe: false});
const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
sphere.position.set(0,5,0);
sphere.position.z = 60;
//scene.add(sphere);

sphere.castShadow = true;








const kupgeo = new THREE.SphereGeometry(20,50,50);
const kupmater = new THREE.MeshStandardMaterial({
    color: 0XFFEA00,
    metalness: 0,
    roughness: 0
});
const kup =new THREE.Mesh(kupgeo,kupmater);
kup.position.set(0,80,0);
//scene.add(kup);


const spotLight = new THREE.SpotLight(0xffbf00 );
scene.add(spotLight);
spotLight.position.set(0, 90, 0);
spotLight.castShadow = true;
spotLight.angle = 0.8;
spotLight.power = 100000;
spotLight.penumbra = 0.8;
spotLight.intensity = 100000;
spotLight.target =kup;
const sLightHelper = new THREE.SpotLightHelper(spotLight);
//scene.add(sLightHelper);




//InitWebSocket()


function InitWebSocket()
{
    //websock = new WebSocket('ws://'+window.location.hostname+':81/');
    websock = new WebSocket('ws://78.186.201.56:81/');
    websock.onmessage=function(evt)
    {

        JSONobj = JSON.parse(evt.data);
        if(JSONobj.distance){
           // document.getElementById('distance').innerHTML = JSONobj.distance;
            var readdist =JSONobj.distance;
            console.log(JSONobj);
            kup.position.x = readdist;
           // kup.position.y = readdist;
            //spotLight.position.x=readdist;
            spotLight.position.x=readdist;
        }
    }
}






const ambientLight = new THREE.AmbientLight(0X333333);
scene.add(ambientLight);
ambientLight.power = 10;
ambientLight.intensity = 10;

/* const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 0.8);
scene.add(directionalLight);
directionalLight.position.set(-30, 50, 0);
directionalLight.castShadow = true;
directionalLight.shadow.camera.bottom = -12;

const dLightHelper = new THREE.DirectionalLightHelper(directionalLight, 5);
scene.add(dLightHelper);

const dLightShadowHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
scene.add(dLightShadowHelper);
 */


const loadingManager = new THREE.LoadingManager();

const progressBar = document.getElementById('progress-bar');

loadingManager.onProgress = function(_url, loaded, total) {
    progressBar.value = (loaded / total) * 150;
    document.getElementById('load').innerHTML = progressBar.value;
}

const progressBarContainer = document.querySelector('.progress-bar-container');

loadingManager.onLoad = function() {
    progressBarContainer.style.display = 'none';
}

const gltfLoader = new GLTFLoader(loadingManager);
const rgbeLoader = new RGBELoader(loadingManager);
let mixer;
gltfLoader.load(motorUrl.href, function(gltf) {
    gltf.scene.scale.set(85, 85, 85);
    //gltf.scene.rotation.x = -0.5 * Math.PI;
    //gltf.scene.rotation.z = -0.5 * Math.PI;
    gltf.scene.position.set(0,0,-8);
    gltf.receiveShadow = true;
    gltf.castShadow = true;
    const model = gltf.scene;
    scene.add(model);
    mixer = new THREE.AnimationMixer(model);
    const clips = gltf.animations;
    const clip = THREE.AnimationClip.findByName(clips, 'HeadActiom');
    const action = mixer.clipAction(clip);
    action.play();
    //model.position.set(0, 10, 100);
}, undefined, function(error){
    console.error(error);
});

//const gui = new dat.GUI();

const options = {
    sphereColor: '#ffea00',
    wireframe: false,
    speed: 0.01,
    angle: 0.7,
    penumbra: 0,
    intensity: 10000
};

/* gui.addColor(options, 'sphereColor').onChange(function(e){
    sphere.material.color.set(e);
});

gui.add(options,'wireframe').onChange(function(e){
    sphere.material.wireframe = e;
});

gui.add(options, 'speed', 0, 0.1);
gui.add(options, 'angle', 0, 1);
gui.add(options, 'penumbra', 0, 1);
gui.add(options, 'intensity', 0, 10000); */


const loader = new RGBELoader();
loader.load(hdrTextureURL,function(texture){
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.background = texture;
    scene.environment = texture;

});

let step = 0;

function animate(time){
    box.rotation.x = time/1000;
    box.rotation.y = time/1000;
    box.rotation.z= time/1000;

    //step += options.speed;
    sphere.position.y= 10 * Math.abs(Math.sin(step));

    //spotLight.angle = options.angle;
    //spotLight.penumbra = options.penumbra;
    //spotLight.intensity = options.intensity;
    sLightHelper.update();


    renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
});

/**index js */
/* Please ❤ this if you like it! */


(function($) { "use strict";

	//Page cursors

    document.getElementsByTagName("body")[0].addEventListener("mousemove", function(n) {
        t.style.left = n.clientX + "px",
		t.style.top = n.clientY + "px",
		e.style.left = n.clientX + "px",
		e.style.top = n.clientY + "px",
		i.style.left = n.clientX + "px",
		i.style.top = n.clientY + "px"
    });
    var t = document.getElementById("cursor"),
        e = document.getElementById("cursor2"),
        i = document.getElementById("cursor3");
    function n(t) {
        e.classList.add("hover"), i.classList.add("hover")
    }
    function s(t) {
        e.classList.remove("hover"), i.classList.remove("hover")
    }
    s();
    for (var r = document.querySelectorAll(".hover-target"), a = r.length - 1; a >= 0; a--) {
        o(r[a])
    }
    function o(t) {
        t.addEventListener("mouseover", n), t.addEventListener("mouseout", s)
    }

	//Navigation

	var app = function () {
		var body = undefined;
		var menu = undefined;
		var menuItems = undefined;
		var init = function init() {
			body = document.querySelector('body');
			menu = document.querySelector('.menu-icon');
			menuItems = document.querySelectorAll('.nav__list-item');
			applyListeners();
		};
		var applyListeners = function applyListeners() {
			menu.addEventListener('click', function () {
				return toggleClass(body, 'nav-active');
			});
		};
		var toggleClass = function toggleClass(element, stringClass) {
			if (element.classList.contains(stringClass)) element.classList.remove(stringClass);else element.classList.add(stringClass);
		};
		init();
	}();


	//Switch light/dark

	$("#switch").on('click', function () {
		if ($("body").hasClass("light")) {
			$("body").removeClass("light");
			$("#switch").removeClass("switched");
		}
		else {
			$("body").addClass("light");
			$("#switch").addClass("switched");
		}
	});

})(jQuery);